//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  components: {
    OrdiniDesktop: () =>
      import("@/components/ordini/desktop/ordini_main_desktop.vue"),
    OrdiniMobile: () => import("@/components/ordini/mobile/ordini_main_mobile"),
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      lista: "get_lista_ordini",
    }),
  },
};
